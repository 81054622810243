@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0px;
}

.image_collage {
  opacity: 0.9;
  filter: alpha(opacity=40);
  margin: 7px;
  padding: 5px;
  border-radius: 3px;
  box-shadow: 0 1px 3px darkgray;
}

.image_collage:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.phil-masonary {
}

ul {
  padding: 0;
  list-style: none;
}

.grid {
  margin: 0 auto;
}

.header {
  display: flex;
  flex-direction: column;
  object-fit: contain;
  background-color: #ddd;
  margin-bottom: 10px;
  flex: 1 0 10%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.app__title {
  color: #444;
  margin: 2px;
  font-family: "Josefin Sans", cursive;
}

.footer {
  height: 100px;
  background-color: #ddd;
  margin-top: 10px;
}

