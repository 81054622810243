@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");

.header {
  display: flex;
  flex-direction: column;
  object-fit: contain;
  background-color: #ddd;
  margin-bottom: 10px;
  flex: 1 0 10%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.app__title {
  color: #444;
  margin: 2px;
  font-family: "Josefin Sans", cursive;
}
