* {
  margin: 0px;
}

.image_collage {
  opacity: 0.9;
  filter: alpha(opacity=40);
  margin: 7px;
  padding: 5px;
  border-radius: 3px;
  box-shadow: 0 1px 3px darkgray;
}

.image_collage:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.phil-masonary {
}

ul {
  padding: 0;
  list-style: none;
}

.grid {
  margin: 0 auto;
}
